/*  Sharp Sans Display */

@font-face {
  font-family: "sharp_sans_display";
  src: url("/fonts/sharpsansdisplayno-black.woff2") format("woff2"),
    url("/fonts/sharpsansdisplayno-black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "sharp_sans_display";
  src: url("/fonts/sharpsansdisplayno-extrabold.woff2") format("woff2"),
    url("/fonts/sharpsansdisplayno-extrabold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "sharp_sans_display";
  src: url("/fonts/sharpsansdisplayno-bold.woff2") format("woff2"),
    url("/fonts/sharpsansdisplayno-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "sharp_sans_display";
  src: url("/fonts/sharpsansdisplayno-semibold.woff2") format("woff2"),
    url("/fonts/sharpsansdisplayno-semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "sharp_sans_display";
  src: url("/fonts/sharpsansdisplayno-medium.woff2") format("woff2"),
    url("/fonts/sharpsansdisplayno-medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "sharp_sans_display";
  src: url("/fonts/sharpsansdisplayno-book.woff2") format("woff2"),
    url("/fonts/sharpsansdisplayno-book.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "sharp_sans_display";
  src: url("/fonts/sharpsansdisplayno-light.woff2") format("woff2"),
    url("/fonts/sharpsansdisplayno-light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "sharp_sans_display";
  src: url("/fonts/sharpsansdisplayno-thin.woff2") format("woff2"),
    url("/fonts/sharpsansdisplayno-thin.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "sharp_sans_display";
  src: url("/fonts/sharpsansdisplayno-hairline.woff2") format("woff2"),
    url("/fonts/sharpsansdisplayno-hairline.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

/*  Sharp Sans Display Italics */

@font-face {
  font-family: "sharp_sans_display";
  src: url("/fonts/sharpsansdisplayno-blackoblique.woff2") format("woff2"),
    url("/fonts/sharpsansdisplayno-blackoblique.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "sharp_sans_display";
  src: url("/fonts/sharpsansdisplayno-extraboldoblique.woff2") format("woff2"),
    url("/fonts/sharpsansdisplayno-extraboldoblique.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "sharp_sans_display";
  src: url("/fonts/sharpsansdisplayno-boldoblique.woff2") format("woff2"),
    url("/fonts/sharpsansdisplayno-boldoblique.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "sharp_sans_display";
  src: url("/fonts/sharpsansdisplayno-semiboldoblique.woff2") format("woff2"),
    url("/fonts/sharpsansdisplayno-semiboldoblique.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "sharp_sans_display";
  src: url("/fonts/sharpsansdisplayno-mediumoblique.woff2") format("woff2"),
    url("/fonts/sharpsansdisplayno-mediumoblique.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "sharp_sans_display";
  src: url("/fonts/sharpsansdisplayno-bookoblique.woff2") format("woff2"),
    url("/fonts/sharpsansdisplayno-bookoblique.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "sharp_sans_display";
  src: url("/fonts/sharpsansdisplayno-lightoblique.woff2") format("woff2"),
    url("/fonts/sharpsansdisplayno-lightoblique.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "sharp_sans_display";
  src: url("/fonts/sharpsansdisplayno-thinoblique.woff2") format("woff2"),
    url("/fonts/sharpsansdisplayno-thinoblique.woff") format("woff");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "sharp_sans_display";
  src: url("/fonts/sharpsansdisplayno-hairlineoblique.woff2") format("woff2"),
    url("/fonts/sharpsansdisplayno-hairlineoblique.woff") format("woff");
  font-weight: 100;
  font-style: italic;
}
