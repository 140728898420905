@media only screen and (min-width: 768px) and (hover: none) and (orientation: portrait) {
  html:after {
    content:'';
    position: fixed;
    left: 0; top: 0; right: 0; bottom: 0;
    z-index: 4242;
    background: #fff;
  }
  body:after {
    content:'';
    position: fixed;
    left: 24px; top: 16px; right: 0; bottom: 0;
    width: 192px; height: 101px;
    z-index: 4243;
    background: url(/static_assets/dpgmedia-logo-rgb-768x404.png) no-repeat left top;
    background-size: contain;
  }
  body:before {
    content:'';
    position: fixed;
    left: 0; top: 50%; right: 0; height: 50vw;
    transform: translateY(-50%);
    z-index: 4243;
    background: url(/images/rotate.png) no-repeat center center;
    background-size: contain;
  }
}

